/* eslint-disable object-property-newline */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-console */
import React, {
  memo, useCallback, useRef,
  useEffect, useReducer, useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Alert,
  Input
} from "reactstrap";
import { Accordion } from "react-bootstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { fetchIbParents } from "store/client/actions";
import { createSharedIbAgreement } from "store/actions";
import validatePositiveInputs from "helpers/validatePositiveInputs";
import AvFieldSelect from "components/Common/AvFieldSelect";
import memoize from "memoize-one";
import { FixedSizeList as List } from "react-window";

const getAccountType = (allAccountTypes, accountTypeId) => {
  return allAccountTypes.find((acc) => acc._id === accountTypeId);
};

const AgreementTotals = ({ type, accIdx, dispatcher, agreementName, totals, aggrementTotalsItem, changeAggrementItem }) => {
  const { t } = useTranslation();
  return (<Row key={type._id} className="my-1 align-items-center">
    <Col md="3">
      {agreementName}
      <AvField
        name={`totals[${accIdx}].accountTypeId`}
        value={type.accountTypeId}
        type="hidden"
      />
    </Col>
    <Col>
      <AvField
        name={`totals[${accIdx}].rebate`}
        // value={accIdx.rebate}
        value={aggrementTotalsItem[accIdx]?.rebate ?? "0"}
        bsSize="sm"
        type="text"
        errorMessage={t("Invalid Rebate value!")}
        validate={{
          required: { value: true },
          min: { value: 0 },
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "rebate", `${e.target.value}`);
            dispatcher({
              type: "UPDATE_TOTAL_REBATE",
              payload: {
                accountTypeId: type.accountTypeId,
                rebate: `${e.target.value}`,
              },
            });
          }
        }}
      />
    </Col>
    <Col>
      <AvField
        name={`totals[${accIdx}].commission`}
        bsSize="sm"
        type="text"
        // value={accIdx.commission}
        value={aggrementTotalsItem[accIdx]?.commission ?? "0"}
        errorMessage={t("Invalid Commission value!")}
        validate={{
          required: { value: true },
          min: { value: 0 },
        }}
        onChange={(e) => {
          if (!isNaN(e.target.value)) {
            changeAggrementItem(accIdx, "commission", `${e.target.value}`);
            dispatcher({
              type: "UPDATE_TOTAL_COMMISSION",
              payload: {
                accountTypeId: type.accountTypeId,
                commission: `${e.target.value}`,
              },
            });
          }
        }}
      />
    </Col>
  </Row>);
};

const MemoizedAgreementTotals = memo(AgreementTotals);

const InvestorForm = ({ type, accIdx, dispatcher, agreementName, memberIdx, member, membersItems, changeMembersItem, inputRefs }) => {
  const { t } = useTranslation();
  // return (<Accordion.Item key={type.accountTypeId} eventKey={accIdx} alwaysOpen>
  //   <Accordion.Header>
  //     <Row className="justify-content-center mb-2 fw-bold gx-0">
  //       <Col md="12" className="text-center">
  //         {agreementName}
  //       </Col>
  //     </Row>
  //   </Accordion.Header>
  //   <Accordion.Body>
  //     <Row>
  //       <Col md="3" className="text-center">
  //         {t("PAMM Profit Share")} %
  //         <AvField
  //           name={`members[${memberIdx}].values[${accIdx}].accountTypeId`}
  //           value={type.accountTypeId}
  //           type="hidden"
  //         />
  //       </Col>
  //       <Col md="9" className="text-center">
  //         <AvField
  //           name={`members[${memberIdx}].values[${accIdx}].pamProfitShare`}
  //           type="string"
  //           errorMessage={t("Invalid Profit Share!")}
  //           validate={{
  //             required: { value: true },
  //             min: { value: 0 }
  //           }}
  //           value={`${type.pamProfitShare}`}
  //           onChange={(e) => {
  //             dispatcher({
  //               type: "UPDATE_MEMBER_PAMM_PROFIT_SHARE",
  //               payload: {
  //                 accountTypeId: type.accountTypeId,
  //                 customerId: member.customerId,
  //                 pamProfitShare: parseFloat(e.target.value),
  //               },
  //             });
  //           }}
  //           onKeyPress={(e) => validatePositiveInputs(e)}
  //         />
  //       </Col>
  //     </Row>
  //   </Accordion.Body>
  // </Accordion.Item>);
  return (
    <>
      <Row className="justify-content-center mb-2 fw-bold gx-0">
        <Col md="12" className="text-center">
          {agreementName}
        </Col>
      </Row>
      <Row>
        <Col md="3" className="text-center">
          {t("PAMM Profit Share")} %
          <AvField
            name={`members[${memberIdx}].values[${accIdx}].accountTypeId`}
            value={type.accountTypeId}
            type="hidden"
            ref={(el) => (inputRefs.current[memberIdx] = el)}
          />
        </Col>
        <Col md="9" className="text-center">
          <AvField
            name={`members[${memberIdx}].values[${accIdx}].pamProfitShare`}
            value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.pamProfitShare}`}
            ref={(el) => (inputRefs.current[memberIdx] = el)}
            type="text"
            errorMessage={t("Invalid Profit Share!")}
            validate={{
              required: { value: true },
              min: { value: 0 }
            }}
            // value={`${type.pamProfitShare}`}
            onChange={(e) => {
              changeMembersItem(memberIdx, "pamProfitShare", e.target.value, accIdx);
              dispatcher({
                type: "UPDATE_MEMBER_PAMM_PROFIT_SHARE",
                payload: {
                  accountTypeId: type.accountTypeId,
                  customerId: member.customerId,
                  pamProfitShare: parseFloat(e.target.value),
                },
              });
            }}
            onKeyPress={(e) => validatePositiveInputs(e)}
          />
        </Col>
      </Row>
    </>
  );
};

const AccountForm = ({
  type,
  accIdx,
  dispatcher,
  agreementName,
  memberIdx,
  member,
  products,
  markups,
  membersItems,
  changeMembersItem,
  changeMembersProductItem,
  inputRefs
}) => {
  const { t } = useTranslation();

  return (
    <div className="border-bottom">
      <Row className="w-100 my-1 align-items-center my-0 py-2 border border-primary bg-primary">
        <Col md="3">
          {agreementName}
          <AvField
            name={`members[${memberIdx}].values[${accIdx}].accountTypeId`}
            value={type.accountTypeId}
            type="hidden"
            ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-accountTypeId`] = el)}
          />
          <AvField
            name={`members[${memberIdx}].level`}
            value={String(member.level)}
            type="hidden"
            ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-level`] = el)}
          />
          <AvField
            name={`members[${memberIdx}].customerId`}
            value={member.customerId}
            type="hidden"
            ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-customerId`] = el)}
          />
        </Col>
        <Col>
        <input
        style={{
          width: "120px",
          display: "inline-block",
          outline: "none",
          border: "1px solid #ced4da",
          borderRadius: "4px",
          fontSize: "12px",
          padding: "0.375rem 0.75rem",
          color: "#495057",
          backgroundColor: "#fff",
          backgroundClip: "padding-box",
        }}
            name={`members[${memberIdx}].values[${accIdx}].rebate`}
            value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.rebate}`}
            type="text"
            ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-rebate`] = el)}
            required
            min={0}
            max={100}
            onChange={(e) => {
              const value = parseFloat(e.target.value.replace(/[a-zA-Z]/g, ""));
              console.log(value);
            if (isNaN(value)) {
              e.target.value = "";
            }
            if (value < 0) {
              e.target.value = 0; 
            } else if (value > 100) {
              e.target.value = 100; 
            }


            localStorage.setItem("type", "rebate");
            localStorage.setItem("product", "false");

              changeMembersItem(memberIdx, "rebate", e.target.value, accIdx);
              dispatcher({
                type: "UPDATE_MEMBER_REBATE_TOTAL",
                payload: {
                  accountTypeId: type.accountTypeId,
                  rebate: parseFloat(e.target.value),
                  customerId: member.customerId,
                },
              });
            }}
          />
        </Col>
        <Col>
        <input
        style={{
          width: "120px",
          display: "inline-block",
          outline: "none",
          border: "1px solid #ced4da",
          borderRadius: "4px",
          fontSize: "12px",
          padding: "0.375rem 0.75rem",
          color: "#495057",
          backgroundColor: "#fff",
          backgroundClip: "padding-box",
        }}
            name={`members[${memberIdx}].values[${accIdx}].commission`}
            value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.commission}`}
            type="text"
            ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-commission`] = el)}
            required
            min={0}
            max={100}
            onChange={(e) => {
              const value = parseFloat(e.target.value.replace(/[a-zA-Z]/g, ""));
              console.log(value);
            if (isNaN(value)) {
              e.target.value = "";
            }
            if (value < 0) {
              e.target.value = 0; 
            } else if (value > 100) {
              e.target.value = 100; 
            }


            localStorage.setItem("type", "commission");
            localStorage.setItem("product", "false");

              changeMembersItem(memberIdx, "commission", e.target.value, accIdx);
              dispatcher({
                type: "UPDATE_MEMBER_COMMISSION_TOTAL",
                payload: {
                  accountTypeId: type.accountTypeId,
                  commission: parseFloat(e.target.value),
                  customerId: member.customerId,
                },
              });
            }}
          />
        </Col>
      </Row>
      {Object.keys(type?.products ?? {})?.map((prod, prodIdx) => (
        <Row key={prodIdx} className="my-1 align-items-center">
          <Col md="3">{products.find((p) => p?.toLowerCase() === prod?.toLowerCase())}</Col>
          <Col>
            {/* <input
              name={`members[${memberIdx}].values[${accIdx}].products.${prod}.rebate`}
              value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.products?.[prod]?.rebate}`}
              // value={1}
              ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-${prod}-rebate`] = el)}
              // bsSize="sm"
              type="text"
              // validate={{
              //   required: { value: true },
              //   min: {
              //     value: 0,
              //     errorMessage: "Minimum is 0"
              //   }
              // }}
              // errorMessage={t("Invalid Product Rebate value!")}
              onKeyPress={(e) => validatePositiveInputs(e)}
              onChange={(e) => {
                changeMembersProductItem(memberIdx, "rebate", e.target.value, accIdx, prod?.toLowerCase());
                dispatcher({
                  type: "UPDATE_MEMBER_REBATE_PRODUCT",
                  payload: {
                    accountTypeId: type.accountTypeId,
                    rebate: parseFloat(e.target.value),
                    customerId: member.customerId,
                    product: prod,
                  },
                });

                // const input = inputRefs.current[`${memberIdx}-${accIdx}-${prod}-commission`];
                // if (input) {
                //   input.focus();
                // }
              }}
            /> */}
            <input
              style={{
                  width: "120px",
                  display: "inline-block",
                  outline: "none",
                  border: "1px solid #ced4da",
                  borderRadius: "4px",
                  fontSize: "12px",
                  padding: "0.375rem 0.75rem",
                  color: "#495057",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                }}
          name={`members[${memberIdx}].values[${accIdx}].products.${prod}.rebate`}
          value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.products?.[prod]?.rebate}`}
          type="text"
          ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-${prod}-rebate`] = el)}
          required
          min={0}
          max={100}
          onChange={(e) => {
            const value = parseFloat(e.target.value.replace(/[a-zA-Z]/g, ""));
            if (isNaN(value)) {
              e.target.value = "";
            }
            // Validate the value within min/max range
            if (value < 0) {
              e.target.value = 0; // Set the value to the minimum if it's less than 0
            } else if (value > 100) {
              e.target.value = 100; // Set the value to the maximum if it's greater than 100
            }

            localStorage.setItem("type", "rebate");
            localStorage.setItem("product", "true");
            // Proceed with your updates
            changeMembersProductItem(memberIdx, "rebate", e.target.value, accIdx, prod?.toLowerCase());
            dispatcher({
              type: "UPDATE_MEMBER_REBATE_PRODUCT",
              payload: {
                accountTypeId: type.accountTypeId,
                rebate: parseFloat(e.target.value),
                customerId: member.customerId,
        product: prod,
      },
    });

    const input = inputRefs.current[`${memberIdx}-${accIdx}-rebate`];
    if (input) {
      // input.focus();
      // console.log(input);
    }
  }}
/>

          </Col>
          <Col>
            <input
              name={`members[${memberIdx}].values[${accIdx}].products.${prod}.commission`}
              value={`${membersItems?.[memberIdx]?.values?.[accIdx]?.products?.[prod]?.commission}`}
              ref={(el) => (inputRefs.current[`${memberIdx}-${accIdx}-${prod}-commission`] = el)}
              type="text"
              onChange={(e) => {
                const value = parseFloat(e.target.value.replace(/[a-z A-Z]/g, ""));
            // Validate the value within min/max range
            if (isNaN(value)) {
              e.target.value = "";
            }
            if (value < 0) {
              e.target.value = 0; // Set the value to the minimum if it's less than 0
            } else if (value > 100) {
              e.target.value = 100; // Set the value to the maximum if it's greater than 100
            }
                changeMembersProductItem(memberIdx, "commission", e.target.value, accIdx, prod?.toLowerCase());
                dispatcher({
                  type: "UPDATE_MEMBER_COMMISSION_PRODUCT",
                  payload: {
                    accountTypeId: type.accountTypeId,
                    commission: parseFloat(e.target.value),
                    customerId: member.customerId,
                    product: prod,
                  },
                });
                localStorage.setItem("type", "commission");
                localStorage.setItem("product", "true");
                const input = inputRefs.current[`${memberIdx}-${accIdx}-${prod}-commission`];
                if (input) {
                  input.focus();
                }
              }}

              style={{
                width: "120px",
                display: "inline-block",
                outline: "none",
                border: "1px solid #ced4da",
                borderRadius: "4px",
                fontSize: "12px",
                padding: "0.375rem 0.75rem",
                color: "#495057",
                backgroundColor: "#fff",
                backgroundClip: "padding-box",
              }}

            />
          </Col>
        </Row>
      ))}
      <Row>
        <Col md="3">Markup</Col>
        <Col>
          <AvFieldSelect
            name={`members[${memberIdx}].values[${accIdx}].markup`}
            options={(markups || []).map((obj) => ({
              label: `${obj}`,
              value: obj
            }))}
          />
        </Col>
      </Row>
    </div>
  );
};


const MemoizedInvestorForm = memo(InvestorForm);
const MemoizedAccountForm = memo(AccountForm);
// {totals?.map((type, accIdx) => (
//   <MemoizedAgreementTotals key={accIdx} type={type} accIdx={accIdx} dispatcher={dispatcher}
//     agreementName={getAccountType(allAccountTypes, type.accountTypeId)?.title}
//   />
// ))}
const RowAggreementTotalsItem = ({ data, index, style }) => {
  const { t } = useTranslation();
  const { allAccountTypes, totals, dispatcher, aggrementTotalsItem, changeAggrementItem } = data;
  const type = totals[index];
  return (<div style={{
    ...style,
    width: "97%",
  }}>
    {
      <MemoizedAgreementTotals type={type} accIdx={index} dispatcher={dispatcher} 
        aggrementTotalsItem={aggrementTotalsItem} changeAggrementItem={changeAggrementItem} totals={totals}
        agreementName={getAccountType(allAccountTypes, type.accountTypeId)?.title}
      />
    }
  </div>);
};

const createAggrementTotalItemData = memoize((allAccountTypes, totals, dispatcher, aggrementTotalsItem, changeAggrementItem) => ({
  allAccountTypes, 
  totals, 
  dispatcher, 
  aggrementTotalsItem, 
  changeAggrementItem,
}));

const RowMemberValueItem = ({ data, member, memberIdx, markups, products, membersItems, changeMembersItem, changeMembersProductItem, inputRefs }) => {
  const { allAccountTypes, dispatcher, members, aggrementTotal, index } = data?.data;
  const value = member?.values?.[data?.index];
  return (<div style={{
    ...data?.style,
    width: "97%",
  }}>
    {
      (getAccountType(allAccountTypes, value?.accountTypeId))?.type === "INVESTOR" ? 
        <MemoizedInvestorForm 
          type={value}
          accIdx={data?.index}
          dispatcher={dispatcher}
          agreementName={getAccountType(allAccountTypes, value?.accountTypeId)?.title}
          memberIdx={memberIdx}
          member={member}
          membersItems={membersItems}
          changeMembersItem={changeMembersItem}
          changeMembersProductItem={changeMembersProductItem}
          inputRefs={inputRefs}
        /> 
        :
        <MemoizedAccountForm 
          type={value}
          accIdx={data?.index}
          dispatcher={dispatcher}
          agreementName={getAccountType(allAccountTypes, value?.accountTypeId)?.title}
          memberIdx={memberIdx}
          member={member}
          products={products}
          markups={markups}
          membersItems={membersItems}
          changeMembersItem={changeMembersItem}
          changeMembersProductItem={changeMembersProductItem}
          inputRefs={inputRefs}
        />
  
      // <MemoizedAgreementTotals type={type} accIdx={index} dispatcher={dispatcher} 
      //   aggrementTotal={aggrementTotal} changeAggrementItem={changeAggrementItem} totals={totals}
      //   agreementName={getAccountType(allAccountTypes, type.accountTypeId)?.title}
      // />
    }
  </div>);
};

const createMemberValueItemData = memoize((allAccountTypes, members, dispatcher, products, markups, membersItems, changeMembersItem, changeMembersProductItem) => ({
  allAccountTypes,
  members,
  dispatcher,
  products,
  markups,
  membersItems, 
  changeMembersItem,
  changeMembersProductItem,
}));


const AGGREMENT_TOTAL_TYPE = (ele = {}) => {
  return {
    accountTypeId: ele?.accountTypeId,
    rebate: "0",
    commission: "0",
  };
};

const AddSharedIbModal = ({
  show,
  toggle,
  accountTypes: allAccountTypes,
  products,
  clientId,
  markups,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [productsModel, setProductsModel] = useState({});
  const [submissionFailure, setSubmissionFailure] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [isAgreementValid, setIsAgreementValid] = useState(false);
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const { submitting, clearingCounter } = useSelector((state) => state.ibAgreements);
  const initState = { 
    title: "",
    totals: [],
    members: [],
  };
  
  const { parents } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );

  // const calculateProducts = (products, product, type, value) => {
  //   if (product) {
  //     const newProducts = products;
  //     newProducts[product][type] = value;
  //     return newProducts;
  //   } else {
  //     // change for all
  //     const newProducts = products;
  //     Object.keys(newProducts).forEach((prod) => {
  //       newProducts[prod][type] = value;
  //     }
  //     );
  //     return newProducts;
  //   }
  // };

  const [payload, dispatcher] = useReducer((state, action) => {
    switch (action.type) {
      case "RESET":
        return initState;
      case "INIT": 
        return {
          ...action.payload,
        };
      case "UPDATE_TITLE":
        return {
          ...state,
          title: action.payload,
        };
      case "INIT_TOTALS":
        return {
          ...state,
          totals: action.payload,
        };
      case "UPDATE_TOTAL_REBATE":
        return {
          ...state,
          totals: state.totals.map((total) => {
            if (total.accountTypeId === action.payload.accountTypeId) {
              return {
                ...total,
                rebate: parseFloat(action.payload.rebate),
              };
            }
            return total;
          }),
        };
      case "UPDATE_TOTAL_COMMISSION":
        return {
          ...state,
          totals: state.totals.map((total) => {
            if (total.accountTypeId === action.payload.accountTypeId) {
              return {
                ...total,
                commission: parseFloat(action.payload.commission),
              };
            }
            return total;
          }),
        };
      case "INIT_MEMBERS": 
        return {
          ...state,
          members: action.payload,
        };
      case "UPDATE_MEMBER_REBATE_TOTAL":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    // update the products as well to this value
                    return {
                      ...val,
                      rebate: parseFloat(action.payload.rebate),
                      // products: calculateProducts(
                      //   val?.products,
                      //   null,
                      //   "rebate",
                      //   parseFloat(action.payload.rebate)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_COMMISSION_TOTAL":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      commission: parseFloat(action.payload.commission),
                      // products: calculateProducts(
                      //   val?.products,
                      //   null,
                      //   "commission",
                      //   parseFloat(action.payload.commission)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_REBATE_PRODUCT":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      // products: calculateProducts(
                      //   val?.products,
                      //   action.payload.product,
                      //   "rebate",
                      //   parseFloat(action.payload.rebate)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_COMMISSION_PRODUCT":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                value: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      // products: calculateProducts(
                      //   val?.products,
                      //   action.payload.product,
                      //   "commission",
                      //   parseFloat(action.payload.commission)
                      // ),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      case "UPDATE_MEMBER_PAMM_PROFIT_SHARE":
        return {
          ...state,
          members: state.members.map((member) => {
            if (member.customerId === action.payload.customerId) {
              return {
                ...member,
                values: member.values.map((val) => {
                  if (val.accountTypeId === action.payload.accountTypeId) {
                    return {
                      ...val,
                      pamProfitShare: parseFloat(action.payload.pamProfitShare),
                    };
                  }
                  return val;
                }),
              };
            }
            return member;
          }),
        };
      default: 
        return state;
    }
  }, initState);


  useEffect(() => {
    if (products) {
      const model = {};
      products.forEach((product) => {
        model[product] = {
          rebate: 0,
          commission: 0,
        };
      });
      setProductsModel(model);
    }
  }, [products]);

  useEffect(() => {
    if (accountTypes?.length > 0) {
      const totals = [];
      accountTypes?.filter(
        (acc) => acc?.type?.toUpperCase() !== "INVESTOR"
      ).forEach((acc) => {
        totals.push({
          accountTypeId: acc?._id,
          rebate: 0,
          commission: 0,
        });
      });
      dispatcher({
        type: "INIT_TOTALS",
        payload: totals,
      });
    }
  }, [accountTypes]);

  useEffect(() => {
    if (parents?.length > 0) {
      const members = [];
      parents?.[0].forEach((parent) => {
        members.push({
          customerId: parent?._id,

          level: parent?.level,
          values: accountTypes?.map((acc) => {
            if (acc?.type?.toUpperCase() === "INVESTOR") {
              return {
                accountTypeId: acc?._id,
                pamProfitShare: 0,
              };
            } else {
              return {
                accountTypeId: acc?._id,
                rebate: 0,
                commission: 0,
                products: productsModel,
              };
            }
          }),
        });
      });
      dispatcher({
        type: "INIT_MEMBERS",
        payload: members,
      });
    }
  }, [parents, accountTypes, productsModel]);


  useEffect(() => {
    clientId && dispatch(fetchIbParents({ clientId }));
  }, [clientId]);


  useEffect(() => {
    !submitting && show && setIsAgreementValid(false) && toggle();
  }, [submitting]);

  useEffect(() => {
    !submitting && show && toggle();
  }, [clearingCounter]);

  useEffect(() => {
    setSubmissionFailure(false);
  }, []);

  useEffect(() => {
    if (show && allAccountTypes && allAccountTypes.length > 0) {
      const accountTypes = new Set();
      allAccountTypes.forEach((acc) => {
        // if (acc.title !== "Demo") {
        //   accountTypes.add(acc);
        // }
        if (acc?.type?.toLowerCase() !== "demo") {
          accountTypes.add(acc);
        }
      });
      setAccountTypes(Array.from(accountTypes));
    }
  }, [allAccountTypes, show]);
  

  useEffect(() => {
    return () => {
      setIsAgreementValid(false);
      dispatcher({
        type: "RESET",
      });
    };
  }, []);
  
  const {
    title, totals, members
  } = payload;


  const validateForm = () => {
    if (!title) {
      setIsAgreementValid(false);
      setValidationErrorMessage("Please Enter Agreement Title");
      return false;
    }
    if (totals?.length > 0) {
      // Check for null fields first
      let isValid = true;
      totals.forEach((total) => {
        if (total?.commission === null || total?.rebate === null) {
          isValid = false;
        }
      });
      if (!isValid) {
        setIsAgreementValid(false);
        setValidationErrorMessage("Please Enter Commission and Rebate");
        return false;
      }
    }
    let isMemberValuesValid = true;
    let isSumValid = true;
    let totalsSum = new Map();
    if (members?.length > 0 && totals?.length > 0) {
      // 1st validation is null
      // 2nd validation is sum of members rebate and commission should be less than or equal to totals
      // 3rd validation should be for each members.value's rebate and commission should not be null and the products rebate and commission should not be null and the sum of products rebate and commission should be less than or equal to members rebate and commission
      for (let i = 0;i < totals.length;i++) {
        totalsSum.set(totals[i].accountTypeId, {
          commission: totals[i].commission,
          rebate: totals[i].rebate,
        });
      }
      for (let i = 0;i < members.length;i++) {
        const member = members[i];
        for (let j = 0; j < member.values.length; j++) {
          const value = member.values[j];
          if (value?.rebate === null || value?.commission === null) {
            isMemberValuesValid = false;
            break;
          } else {
            const total = totalsSum.get(value.accountTypeId);
            // subtract the member values from totals if total becomes less than 0 then it is invalid
            if (total) {
              const newTotal = {
                commission: total.commission - value.commission,
                rebate: total.rebate - value.rebate,
              };
              if (newTotal.commission < 0 || newTotal.rebate < 0) {
                isSumValid = false;
                break;
              } else {
                totalsSum.set(value.accountTypeId, newTotal);
              }
            }
          }
        }
        if (!isMemberValuesValid) {
          setIsAgreementValid(false);
          setValidationErrorMessage("Please Enter Commission and Rebate for each member");
          break;
        }
        if (!isSumValid) {
          setIsAgreementValid(false);
          setValidationErrorMessage("Sum of Commission and Rebate for each member should be less than or equal to totals");
          break;
        }
      }

    }
    isMemberValuesValid && isSumValid && setIsAgreementValid(true) & setValidationErrorMessage("");
    return true;
  };


  const [aggrementTotalsItem, setAggrementTotalsItem] = useState([]);
  const [membersItems, setMembersItems] = useState([]);
  useEffect(() => {
    if (totals && aggrementTotalsItem?.length === 0) {
      const itensData = [];
      totals?.forEach((ele) => {
        itensData?.push(AGGREMENT_TOTAL_TYPE(ele));
        setAggrementTotalsItem(itensData);
      });
    }
  }, [totals]);

  useEffect(() => {
    if (members?.length > 0 && (membersItems?.[0]?.values?.length ?? 0) === 0) {
      setMembersItems(JSON.parse(JSON.stringify(members)));
    }
  }, [members]);  

  const listRef = useRef(null);
  const inputRefs = useRef([]);

  function changeAggrementItem(index, key, value) {
    const itemsModified = JSON.parse(JSON.stringify(aggrementTotalsItem));
    itemsModified[index][key] = value;
    setAggrementTotalsItem(itemsModified);
  }

  function changeMembersItem(index, key, value, valueIndex) {

    const scrollTop = listRef.current ? listRef.current.scrollTop : 0;

    const membersModified = JSON.parse(JSON.stringify(membersItems));
    membersModified[index].values[valueIndex][key] = value;
    setMembersItems(membersModified);

    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollTo(0, scrollTop);
      }

      if (localStorage.getItem("product") === "false") {
        let input = inputRefs.current[`${index}-${valueIndex}-${localStorage.getItem("type")}`];
        if (input) {
          console.log(input);
          input.focus();
        }
      }
    }, 0);
  }


  function changeMembersProductItem(index, key, value, valueIndex, productKey) {

    const scrollTop = listRef.current ? listRef.current.scrollTop : 0;

    const membersModified = JSON.parse(JSON.stringify(membersItems));
    membersModified[index].values[valueIndex].products[`${productKey}`][key] = value;
    setMembersItems(membersModified);

    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollTo(0, scrollTop);
      }

      if (localStorage.getItem("product") === "true") {
        let input = inputRefs.current[`${index}-${valueIndex}-${productKey}-${localStorage.getItem("type")}`];
        if (input) {
          console.log(input);
          input.focus();
        }
      } 
    }, 0);

  }
  const rowAggrementTotalHeight = 60;
  const itemAggrementTotalData = createAggrementTotalItemData(allAccountTypes, totals, dispatcher, aggrementTotalsItem, changeAggrementItem);

  const rowMemberValueHeight = 510;
  const itemMemberValueData = createMemberValueItemData(allAccountTypes, members, dispatcher, products, markups, membersItems, changeMembersItem, changeMembersProductItem);

  if (!show) {
    return null;
  }

  return (
    <Modal
      isOpen={show}
      // toggle={() => {
      //   dispatcher({
      //     type: "RESET",
      //   });
      //   toggle();
      // }}
      centered={true}
      scrollable={true}
      onClosed={() => {
        setSubmissionFailure(false);
      }}
    >
      <ModalHeader toggle={()=>{
        dispatcher({
          type: "RESET",
        });
        toggle();
      }} tag="h4">
        {t("New Shared IB Agreement")}
      </ModalHeader>
      <ModalBody>
        {submissionFailure ? (
          <div
            style={{
              display: "block",
              color: "white",
              marginBottom: 10,
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#f65854",
            }}
            className="formValidationErrMessage"
          >
            {"Invalid values found. Please check the form again!"}
          </div>
        ) : null}
        <AvForm
          validationEvent={["onChange", "onInput", "onBlur"]}
          onValidSubmit={(e, v) => {
            setSubmissionFailure(false);
            const valid = validateForm();
            const data = {
              title: v?.title,
              totals: aggrementTotalsItem,
              members: membersItems,
            };
            if (valid) {
              dispatch(
                createSharedIbAgreement(data)
              );
            }
          }}
        >
          <AvField
            name={"title"}
            label={t("Agreement name")}
            className="mb-3"
            type="text"
            value={title}
            errorMessage={t("Required!")}
            validate={{ required: { value: true } }}
            onChange={(e) => {
              dispatcher({
                type: "UPDATE_TITLE",
                payload: e.target.value,
              });
            }}
          />
          <Row className="justify-content-start">
            <Col md="3">{t("Type")}</Col>
            <Col>{t("Total Rebate")}</Col>
            <Col>{t("Total Commission")}</Col>
          </Row>
          {
            aggrementTotalsItem?.length > 0 &&
              <List
                height={300}
                itemCount={totals?.length}
                itemSize={rowAggrementTotalHeight}
                itemData={itemAggrementTotalData}
                width="100%"
              >
                {RowAggreementTotalsItem}
              </List>
          }
          <hr className="my-3" />
          <Accordion className="mb-3" alwaysOpen>
            {members &&
              members
                .sort((a, b) => (a.level > b.level ? 1 : -1))
                .map((member, memberIdx) => {
                  const customer = parents?.[0]?.find((p) => p._id === member.customerId);
                  return <>
                    <Accordion.Item key={member.customerId} eventKey={memberIdx}>
                      <Accordion.Header>
                        {customer?.firstName} {customer?.lastName}
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="my-1" alwaysOpen>
                          <List
                            outerRef={listRef}
                            height={300}
                            itemCount={member?.values?.length}
                            itemSize={rowMemberValueHeight}
                            itemData={itemMemberValueData}
                            width="100%"
                          >
                            {(data)=>(
                              <RowMemberValueItem
                                data={data}
                                member={member}
                                memberIdx={memberIdx}
                                changeMembersItem={changeMembersItem}
                                changeMembersProductItem={changeMembersProductItem}
                                membersItems={membersItems}
                                markups={markups}
                                products={products}
                                inputRefs={inputRefs}
                              />
                            )}
                          </List>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>;
                })}
          </Accordion>
          {
            !isAgreementValid && validationErrorMessage && <Alert color="danger">{validationErrorMessage}</Alert>
          }
          {
            submitting ? <Spinner size="sm" color="primary" /> :
              <Button type="submit" disabled={submitting}>
                {t("Submit")}
              </Button>
          }
        </AvForm>
      </ModalBody>
    </Modal>
  );
};

export default memo(AddSharedIbModal);
